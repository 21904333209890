import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import Vant from "vant";
import { Locale } from "vant";
// 引入法语语言包
import frFR from "vant/es/locale/lang/fr-FR";
Locale.use("fr-FR", frFR);

import "vant/lib/index.css";
import axios from "axios";
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  axios,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
